.notfound {
  position: relative;
  text-align: center;
  margin: 4rem 0;

  &-error {
    font-size: 4rem;
    margin: 1rem 0;
  }

  &-line {
    border-top: 0.4rem solid $default-shade;
    display: block;
    margin: 0 auto 3rem;
    width: 4rem;
  }

  &-message {
    max-width: 25rem;
    margin: 0 auto;
  }
}
